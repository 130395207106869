/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, YouTube, Image, Subtitle } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Pop-Rock"} description={"Pop-rock"}>
        <Column className="pb--60 pt--60" name={"4yduf46y86v"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Passion"}>
              </Title>

              <Text className="text-box" content={"Polystylová kompozice<br>Polystyle composition<br>feat. Ivan Skřítek<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box" use={"page"} href={"/"} content={"Home"}>
              </Button>

              <YouTube embedId={"XQGbZB5TO3w"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="[[ADMINpt]] [[ADMINpb]]" name={"9iw7l0zo4j5"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Compassion"}>
              </Title>

              <Text className="text-box" content={"Rocková úprava<br>Rock arrangement<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"bMKGBJdCdt4"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"302oke6jn2u"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Malá noční hudba"}>
              </Title>

              <Text className="text-box" content={"Malá noční hudba na báseň současného autora Milana Mikuláška<br>Little Hight Music on poem of contemporary czech poet Milan Mikulášek<br>feat. Vladimíra Krčková<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"Dt44PEIihXw"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6vkf6jo22yq"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Compassion"}>
              </Title>

              <Text className="text-box" content={"Videoklip<br>Videoclip<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"INrXPGzj2qg"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"pbssjonnmek"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Bad"}>
              </Title>

              <Text className="text-box" content={"Michael Jackson zní dobře jak v jazzovém hávu, který zastupuje saxofonové kvarteto, tak v klasickém, který zastupuje smyčcové kvarteto<br>Michael Jackson sounds great in classic style (string quartet) and also jazz style (saxophone quartet)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"ifhJBHNxT6I"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"lq2fzbdibu"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Fire"}>
              </Title>

              <Text className="text-box" content={"Hard Rocková píseň<br>Hard Rock song<br>feat. Ivan Skřítek<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=w_El03YhxyI&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} href={"https://www.youtube.com/watch?v=w_El03YhxyI&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Determination"}>
              </Title>

              <Text className="text-box" content={"Rocková píseň s elektrickou kytarou<br>Rock song with guitar"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=W_nneK5BQtQ&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} href={"https://www.youtube.com/watch?v=W_nneK5BQtQ&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=2"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Goodness"}>
              </Title>

              <Text className="text-box" content={"Lyrická píseň s violoncellem<br>Lyrical song with violincello<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=1xjR7bitcDY&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4"} href={"https://www.youtube.com/watch?v=1xjR7bitcDY&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"llyk3uwne7"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Calmness"}>
              </Title>

              <Text className="text-box" content={"Klidná píseň s klarinetem<br>Calm song with clarinet"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=uSul2MIgzqw&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4&t=13s"} href={"https://www.youtube.com/watch?v=uSul2MIgzqw&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=4&t=13s"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Warmness"}>
              </Title>

              <Text className="text-box" content={"Disko píseň<br>Disco song"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=f1mUVjvHTx4&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=5"} href={"https://www.youtube.com/watch?v=f1mUVjvHTx4&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=5"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Ode To Joy"}>
              </Title>

              <Text className="text-box" content={"Multižánrový cover s flétnou, saxofonem a violoncellem\n<br>Multigenre cover with flute, saxophone and violoncello"}>
              </Text>

              <Button className="btn-box" url={"https://www.youtube.com/watch?v=mBTdSqi9p48&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=10"} href={"https://www.youtube.com/watch?v=mBTdSqi9p48&list=PLkTj2Nk6txugm4PBW9VeLpBbwS-RJe7B-&index=10"} target={"_blank"} content={"YouTube"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"co69n6vvx9o"} style={{"backgroundColor":"rgba(192, 192, 192, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"ToneGym"}>
              </Title>

              <Image alt={""} src={"https://cdn.swbpg.com/o/2002/11a31b2a94d847b0ad36d4603444392b.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={""}>
              </Image>

              <Subtitle className="subtitle-box" content={"Diamond Ears Award"}>
              </Subtitle>

              <Text className="text-box" content={"After one year and half of irregular training, I´ve received highest award<br>ToneGym is very methodical and entertaining, I recomend it to all my students<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}